.wheelContainer {
  margin-top: 60px;
  margin-bottom: 30px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imgwheel {
  width: 300px;
}

.palheta {
  height: 0px;
  width: 0px;
  border-left: 15px transparent solid;
  border-right: 15px transparent solid;
  border-top: 50px #F6986C solid;
  position: absolute;
  z-index: 5;
  top: -30px;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: 5px;
  filter: drop-shadow(2px 5px 5px rgba(0, 0, 0, 0.5));
  -webkit-filter: drop-shadow(2px 5px 5px rgba(0, 0, 0, 0.5));
}

.rouletteTop {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: #F6986C;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  .top2 {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    // margin-top: 5.8px;
    // margin-left: 5.8px;
    background: #ffff;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    color: var(--orange-primary);

    svg {
      z-index: 2;
      font-size: 30px;
      color: #DE6E57;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      -o-user-select: none;
      user-select: none;
    }
  }
}

.pulse {
  z-index: 1;
  height: 30px;
  width: 30px;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 50%;
  display: grid;
  place-items: center;
  font-size: 50px;
  color: #ffffff;
}

.pulse:before,
.pulse:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(225, 127, 81, 0.3);
  border-radius: 50%;
  z-index: -1;
  opacity: 0.7;
}

.pulse:before {
  animation: pulse 3s ease-out infinite;
}

.pulse:after {
  animation: pulse 3s 1s ease-out infinite;
}

@keyframes pulse {
  100% {
    transform: scale(2.5);
    opacity: 0;
  }
}

.rotate-loose {
  // 2020
  transform: rotate(2000deg);
  transition: all 3s ease-in-out;
}

.rotate-win {
  // 2070
  transform: rotate(2045deg);
  transition: all 3s ease-in-out;
}