body {
  background: #f5f6fb !important;
}

// FORMS
.manageStoreForm {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.adminform {
  display: flex;
  width: 80%;
}

.columnAdminForm {
  width: 40%;
  border-right: 1px solid var(--arrow);
  padding-right: 20px;

  & .formfield_container {
    margin-bottom: 15px;
  }

  & .MuiButton-root {
    width: fit-content;
  }
}

.headerContent {
  display: flex;
  width: 100%;
  align-items: center;

  & .formfield_container:first-child {
    margin-right: 20px;
  }
}

.contentAdminForm {
  padding-left: 60px;
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  & .formfield_container {
    margin-bottom: 20px;
  }

  & .formfield_container>.MuiFormControlLabel-root {
    margin-left: 0;
  }

  & .MuiButtonBase-root>svg {
    width: 15px;
    height: 14px;
    margin-right: 5px;
  }
}

.imagesAdmin {
  display: flex;
}

.titleContenu {
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  font-size: 15px;
  margin-bottom: 5px;
}

// MENU GAUCHE
.Mui-selected {
  background-color: rgba(246, 152, 108, 0.2) !important;
  color: var(--orange-primary) !important;

  & svg {
    color: var(--orange-primary) !important;
  }
}

// TITRES
.MuiBox-root h1 {
  font-size: 2.5rem !important;
}

// CONTAINER
.containerAdmin {
  margin-top: 45px;
}

// ADD AND MANAGE FORM PAGE HEADER
.addAndManageHeader {
  display: flex;
  margin-bottom: 30px;
  align-items: center;

  & svg {
    font-size: 2.5em;
    color: var(--arrow);
    cursor: pointer;
  }

  & svg:hover {
    color: var(--orange-primary);
  }

  & h2 {
    margin-left: 20px;
  }
}

// ROW CONTAINER
.rowContainer {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

// GRID CONTAINER
.gridContainer {
  display: grid;
  // repeat(4, minmax(300px, 400px));
  grid-template-columns: repeat(auto-fill, minmax(300px, 400px));
  gap: 20px
}

// CARD HEADER
.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

// TITRE
.titreCard {
  font-size: 18px;
  font-weight: 600;
}

// STATUS
.statusCard {
  font-size: 15px;
  padding-left: 15px;

  & span {
    padding: 3px 6px;
  }

  & .offline {
    color: #3d5a80;
    background: #e0fbfc;
  }

  & .online {
    color: rgb(34, 154, 22);
    background: rgb(228, 248, 221);
  }

  & .archived {
    color: #fff;
    background: #e07a5f;
  }
}

// DESCRIPTION CLAMP
.descriptionClamp {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 12px;
}

// CARD FOOTER
.cardFooter {
  display: flex;
  margin-top: auto;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

// CARD ACTIONS
.cardActions {
  display: flex;
  justify-content: flex-end;
}

// LIST
.listEnseignes {
  padding-top: 20px !important;

  & .MuiDivider-root:last-child {
    border-bottom: 0;
  }

  /* Firefox */
  & {
    scrollbar-width: auto;
    scrollbar-color: #e17f51 #ffffff
  }

  /* Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    width: 10px
  }

  &::-webkit-scrollbar-track {
    background: #ffffff
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e17f51;
    border-radius: 10px;
    border: 3px solid #ffffff
  }
}

// EXPORT BUTTON
.exportButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--orange-primary);
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  padding: 5px 15px;
  border-radius: 3px;
  transition: 0.3s;
  border: 0;
  height: 39px;
  cursor: pointer;
  gap: 10px
}