// USER LIST

.containerAdminUser {
  margin-top: 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & h1 {
    margin-bottom: 20px;
  }
}

.filterCheckbox~label {
  background: var(--primary);
  color: #fff;
  font-weight: 700;
  padding: 5px 10px;
  border-radius: 4px;
  transition: .3s;
  cursor: pointer;
}

.filterCheckbox:checked~label {
  background: var(--orange-primary);
  box-shadow: 5px 2px 15px #a8b4fc80;
}

.filterContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  list-style: none;
  position: relative;
  padding: 0;
  gap: 5px;

  & li {
    margin: 10px 5px;
    position: relative;
  }

  & li:last-child {
    margin-left: 10px;
  }
}

.filterCheckbox {
  display: none;
}

.formFilterContainer {
  display: flex;
  justify-content: start;
  margin-bottom: 10px;
}

.counterValidationUser {
  position: absolute;
  background: rgba(237, 78, 74, 1);
  //clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
  clip-path: circle(50% at 50% 50%);
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -10px;
  right: -10px;
  color: white;
  z-index: 1;
  font-size: 11px;
  font-weight: bold;
}

.counterRequests {
  position: absolute;
  background: rgb(237, 78, 74);
  clip-path: circle(50% at 50% 50%);
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 13px;
  right: 5px;
  color: white;
  z-index: 1;
  font-size: 11px;
  font-weight: bold;
}

.userStatusTable {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: fit-content;
}

.labelUserStatus {
  padding: 3px 6px;
  text-align: center;
}

._1UserStatus {
  color: #3d5a80;
  background: #e0fbfc;
}

._2UserStatus {
  color: rgb(34, 154, 22);
  background: rgb(228, 248, 221);
}

._3UserStatus {
  color: #ffff;
  background: #b56576;
}

._4UserStatus {
  color: #ffff;
  background: #e07a5f;
}

.userActionsTable {
  display: flex;
}

.userInfos {
  gap: 20px;
  display: flex;
  align-items: center;
}

.thumbnailUser {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  object-fit: cover;
}

.thumbnailUserContainer {
  display: flex;
  flex-direction: column;
}

// Search bar user
.inputSearchTable {
  display: flex;
  gap: 20px;
  align-items: flex-end;

  & .MuiInputBase-formControl {
    background: white;
    max-height: 38px;
  }

  & #users>.MuiInputBase-root {
    padding: 0 !important
  }

  & .searchInput label {
    font-size: 15px;
    top: -8px
  }

  & .selectStatus {
    & span {
      font-size: 15px;
      color: var(--grey);
    }

    & .MuiFormControl-fullWidth {
      margin-top: 5px;
    }

    & fieldset {
      top: -3px;
    }

    & fieldset legend {
      display: none;
    }
  }

  & .waitingUser {
    margin: 26px 0 20px 0;

    & span {
      color: var(--orange-primary);
      font-weight: 600;
    }
  }
}



// FORMS
.adminUserForm {
  & button {
    float: right;
  }
}

.contentAdminUserForm {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 60px;
  width: 70%;
  justify-content: flex-start;
}

.headerContentUser {
  display: flex;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

// USER DETAILS

.arrow {
  margin-top: 45px;
  margin-bottom: 20px;
  font-size: 2.5em !important;
  color: var(--arrow);
  cursor: pointer;

  & :hover {
    color: var(--orange-primary);
  }
}

.headerUserProfile {
  display: flex;
  margin-left: 20px;
  gap: 20px;
  align-items: center;
}

.containerUserProfile {
  display: flex;
  margin-bottom: 30px;
  align-items: center;
  justify-content: space-between;
}

.containerInfosUserProfile {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & .infos {
    width: 66%;
  }

  & .userPicture {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
}

.infosUserProfile {
  display: flex;
  gap: 10px;
  //border-right: 1px solid black;
  padding: 0 20px;

  & .labelInfosUserProfile {
    font-weight: 600;
  }
}

.tabsUserProfile {
  overflow: hidden;
  display: flex;
  position: relative;
  background-color: rgb(248, 249, 250);
  border-radius: 0.75rem;
  padding: 0.25rem;

  & a,
  button {
    font-size: 15px;
    color: #344866;
    width: 130px;
    border-radius: 0;
  }

  & a:hover,
  button:hover {
    background-color: var(--orange-primary);
    border-radius: 4px !important;
    color: white;
  }

  & svg {
    margin-right: 10px;
  }
}