.modal-container-NewPost {
  position: fixed;
  display: table;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  transform: scale(0);
  z-index: 99;

  &.two {
    transform: scale(1);

    .modal-background {
      background: rgba(0, 0, 0, .0);
      animation: fadeIn .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
      backdrop-filter: blur(15px);
    }

    &.out {
      animation: quickScaleDown 0s .5s linear forwards;

      .modal-background {
        animation: fadeOut .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
      }
    }
  }

  .modal-background {
    display: table-cell;
    background: var(--background);
    text-align: center;
    vertical-align: middle;

    .modalNewPost {
      max-width: 650px;
      text-align: left;
      background: var(--background);
      padding: 30px;
      display: inline-block;
      border-radius: 3px;
      font-weight: 300;
      position: relative;
      width: 90%;
      box-sizing: border-box;

      .containerNewPost {
        width: 100%;
        margin: 20px 0;
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
      }

      .column1 {
        width: 100%;
        height: 150px;
      }

      .columnNewPost {}

      .imageNewPost {
        border-radius: 8px;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }

      .titleNewPost {
        font-size: 20px;
        font-weight: bold;
      }

      .buttonPost {
        display: block;
        background: #f6986c;
        text-align: center;
        width: -moz-fit-content;
        width: fit-content;
        font-weight: 500;
        padding: 8px 20px;
        font-family: "Poppins", sans-serif;
        font-size: 15px;
        letter-spacing: 1px;
        color: white !important;
        text-decoration: none !important;
        border-radius: 15px;
        border: none;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
      }
    }
  }
}