.modal-container-cgu {
  position: fixed;
  display: table;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  transform: scale(0);
  z-index: 99;

  &.two {
    transform: scale(1);

    .modal-background {
      background: rgba(0, 0, 0, .0);
      animation: fadeIn .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
      backdrop-filter: blur(15px);
    }

    &.out {
      animation: quickScaleDown 0s .5s linear forwards;

      .modal-background {
        animation: fadeOut .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
      }
    }
  }

  .modal-background {
    display: table-cell;
    background: var(--background);
    text-align: center;
    vertical-align: middle;

    .modalCgu {
      max-width: 650px;
      text-align: left;
      background: var(--background);
      padding: 30px;
      display: inline-block;
      border-radius: 3px;
      font-weight: 300;
      position: relative;
      width: 90%;
      box-sizing: border-box;

      & h2 {
        margin-bottom: 20px;
      }

      & p {
        line-height: 25px;
      }

      & label {
        font-size: 15px;
      }

      & .cta {
        margin-bottom: 0
      }
    }
  }
}