.groupContainer {
  margin: 20px 0 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  align-items: stretch;
}

.addContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: white;
  font-size: 15px;
  padding: 0 16px;
  cursor: pointer;
  color: var(--orange-primary);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

  &:hover {
    background: var(--orange-primary);
    color: white;
  }

}

.groupDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: white;
  padding: 5px 15px;
  gap: 10px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

  & svg {
    color: var(--orange-primary);
  }
}

.groupActive {
  background: var(--orange-primary);
  color: white;

  & svg {
    color: white;
  }
}