// CONTAINER
.container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
  //height: calc(100vh - 40px - 40px);

  & h3 {
    margin: 25px 0 10px 0;
    color: var(--grey)
  }

  & .button {
    margin: 20px auto;
  }

  & .geosearch {

    & .MuiInput-input {
      padding: 2px 4px 10px 0 !important
    }
  }

  & .geosearch>div>div::before {
    border-bottom: 2px solid var(--secondary) !important
  }

  & .geosearch>div>div::after {
    border-bottom: 2px solid var(--orange-primary) !important
  }

  & .geosearch>div>.Mui-focused {
    color: var(--primary) !important
  }
}

// Register form 1
.registrationForm1 {
  width: calc(100% - 20px);
  display: flex;
  flex-direction: column;
  align-items: center;

  & .buttonContainer {
    width: 300px;
  }
}

.register2 {
  width: 100%;
}

// STEPS REGISTER
.stepsHeader {
  display: flex;
  align-self: center;
  margin: 10px 0;
  justify-content: space-between;

  & div {
    padding: 5px;
    text-transform: uppercase;
    color: var(--grey);
  }
}

// STEP ACTIF
.selected {
  border-bottom: 2px solid var(--orange-primary);
  color: var(--orange-primary) !important;
}

// REGISTRATION FORM
.registrationForm {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  & div>label {
    flex-direction: column;
    border-bottom: none;
    padding-bottom: 5px;
    margin-top: 5px
  }

  & div>label>span>input {
    margin-left: 0;
    padding-left: 0;
    width: 100%;
    border-bottom: 2px solid var(--secondary);
  }

  & div>label>span>input:focus {
    border-bottom: 2px solid var(--orange-primary)
  }
}

// INPUT DATE
input[type=date] {
  align-items: center;
  display: inline-flex;
  font-family: monospace;
  padding-inline-start: 1px;
  cursor: default;
  overflow: hidden;
  padding: 0px;
}

// CONFIRMATION
.confirmation {
  text-align: center;
  margin-top: 60px;

  & h2 {
    margin: 30px 0;
  }
}

// MAP
.mapEmbed {
  margin-top: 20px;
  height: 250px;
  border-radius: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  margin-bottom: 15px;

  & .leaflet-control-attribution {
    display: none;
  }
}

// CGU
.cgu {
  text-decoration: underline !important;
  color: var(--orange-primary) !important;
}

// CHECKMARK ANIMATION ON END
.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: var(--orange-primary);
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px var(--orange-primary);
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {

  0%,
  100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px var(--orange-primary);
  }
}