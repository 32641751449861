.userGainContainer {
  display: flex;
  padding: 10px;
  flex-wrap: wrap;
  justify-content: space-around;
}

.userGain {
  position: relative;
  margin: 10px 10px 20px 10px;
  flex: 1 1 100%;
  background: white;
  padding: 10px 10px 40px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
  gap: 10px;
  text-align: center;

  & img {
    width: 55px;
  }

  & .descriptionGain {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  & .seeGain {
    background: var(--orange-secondary);
    padding: 8px;
    border-radius: 10px;
    color: white;
    position: absolute;
    bottom: -18px;
    margin: 0 auto;
    font-size: 15px;
    letter-spacing: 1px;
  }

  & .conditionsGain {
    color: var(--grey);
    font-size: 12px;
    font-style: italic;
  }

  & .dateMaxGain {
    color: var(--orange-primary);
    font-size: 12px;
    font-style: italic;
  }
}

.userGainLoyalty {
  position: relative;
  margin: 10px;
  flex: 1 1 100%;
  background: white;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
  gap: 10px;
  text-align: center;

  & img {
    width: 55px;
  }

  & .conditionsGain {
    color: var(--grey);
    font-size: 12px;
    font-style: italic;
  }

  & .dateMaxGain {
    color: var(--orange-primary);
    font-size: 12px;
    font-style: italic;
  }
}