.container {
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & .logoBk {
    width: 100px;
    margin: 0px auto 20px auto
  }
}

.logo {
  width: 100;
}

.authContainer {
  display: flex;
  justify-content: center;
  height: 400px;
  flex-direction: column;
}

.authForm {
  width: calc(100% - 20px);
  margin: auto 0;
  display: flex;
  flex-wrap: wrap;
  gap: 20;
  align-items: center;

  & label.input:focus {
    border-bottom: 2px solid var(--outlined) !important
  }
}

.loginForm {
  width: 100%
}

.forgetPassword {
  width: 100%;
  text-align: end;
  font-weight: 300;
  font-size: 15px;
  color: var(--grey)
}

.legalsContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  font-size: 15px;
  color: #707070;
  font-weight: 300;

  :hover {
    color: #f6986c;
    transition: color 0.3s ease;
  }
}