.map {
  list-style: none;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 75px);
  grid-template-rows: repeat(9, 40px);
  grid-gap: 10px;
  margin-bottom: 30px;
}

.hive {
  clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
  position: relative;
  margin-left: -15px;
  grid-column-end: span 1;
  grid-row-end: span 2;
  background: black;
  text-align: center;
  width: 105px;
  height: 105px;
  box-sizing: border-box;

  @for $i from 1 through 4 {
    &[id$='#{$i}'] {
      grid-row-start: $i*2;

      &:nth-child(3n-1) {
        grid-row-start: ($i*2)-1
      }
    }
  }

  &::before {
    top: 50%;
    left: 0;
    width: 0;
    height: 100px;
    content: ' ';
    //clip-path: polygon(11% 33%, 90% 33%, 100% 50%, 90% 69%, 14% 70%, 0 52%);
    clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
    z-index: 1;
    position: absolute;
    transform: translateY(-50%);
    background: rgba(225, 127, 81, 0.2);
    transition: 0.2s;
  }

  & label {
    position: absolute;
    top: 2px;
    /* equal to border thickness */
    left: 2px;
    /* equal to border thickness */
    width: 101px;
    /* container height - (border thickness * 2) */
    height: 101px;
    /* container height - (border thickness * 2) */
    clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
    background: var(--background);
    display: inline-grid;
    place-content: center;

    img {
      max-width: 60px;
      max-height: 80px;
    }

    .image66f9a362-72f2-448a-a3c1-d5b9c2f3fbd9 {
      height: 60px;
      width: 40px;
    }

    & input[type='radio'] {
      display: none;
    }
  }
}

.selectedDiv {
  background: var(--orange-primary);

  & label {
    top: 3px;
    /* equal to border thickness */
    left: 3px;
    /* equal to border thickness */
    width: 99px;
    /* container height - (border thickness * 2) */
    height: 99px;
  }

  &::before {
    width: 100%;
    content: ' ';
  }

}