.anchors {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 20px auto;

  & a {
    width: 70px;
    height: 70px;
    background: var(--orange-secondary);
    border-radius: 50%;
    color: white;
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    line-height: 10px;
    text-align: center;
  }

  & svg {
    width: 20px;
    height: 20px;
    fill: white
  }
}

.profilContainer {
  padding: 0 20px
}

.modify {
  color: var(--primary);
  font-weight: 300;
}

.infosModify {
  position: absolute;
  top: -30px;
  right: 20px;
}

.accountContainer {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 20px;
  padding: 20px;

  & .button {
    width: 45%;
  }

  & h2 {
    padding-left: 0;
    font-size: 20px;
    margin-top: 15px;
    margin-bottom: 15px;
  }

}

.form {

  & div>label {
    flex-direction: column;
    border-bottom: none;
    font-size: 15px;
    padding-bottom: 0;
    margin-top: 10px;
    padding-left: 0;
    position: relative;

    & svg {
      position: absolute;
      bottom: 7px;
      right: 7px;
    }
  }

  & div>label>span>input {
    margin-left: 0;
    padding-left: 0;
    font-weight: 700;
    color: var(--font);
    font-size: 16px;
    width: 100%;
    border-bottom: 2px solid var(--secondary)
  }

  & div>label>span>input:focus {
    border-bottom: 2px solid var(--orange-primary)
  }

  & .primary {
    width: 40%;
  }
}

.infosUserPageContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;

  .infosUserPage {
    display: flex;
    flex-direction: column;
    border-bottom: 2px solid var(--secondary);
    font-size: 15px;
    font-weight: 600;
    margin-top: 10px;
    color: var(--primary);
    padding: 5px 10px 5px 0;

    &:last-child {
      margin-bottom: 10px;
    }

    & span {
      color: var(--secondary);
      font-weight: 400;
    }
  }
}

.modifyPassword {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;

  & h3 {
    font-weight: 400;
  }
}

.buttonsUser {
  margin-top: 20px;
  margin-bottom: 20px;

  & .button {
    //width: 55%;
    padding: 8px 20px;
  }
}

.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;

  & div {
    text-align: center;
  }

  & h3 {
    font-weight: 600;
    font-size: 20px;
    transform: translateX(-15px)
  }

  & a {
    color: var(--grey);
    font-weight: 300
  }
}

.titleSocialsContainer {
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;

  &::after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 0px;
    right: 0px;
    margin: 0 auto;
    background: var(--secondary);
    width: 12em;
    height: 2px
  }

  & .titleSocials {
    margin: 0;
    font-size: 24px;
  }
}


.iconsContact {
  color: var(--orange-primary);
  font-size: 1em;
  margin-right: 10px;
}


.links {
  margin-top: 10px;
  text-align: center;

  & a {
    font-size: 12px;
    color: var(--grey)
  }
}