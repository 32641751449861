// ACTIVE NAVBAR
.active {
  color: var(--orange-primary) !important;

  & svg {
    color: var(--orange-primary) !important
  }
}

.background-nav {
  position: absolute;
  top: 50%;
  left: 49.4%;
  transform: translate(-50%, -50%);
}

// BOTTOM NAV BAR
.bottom-appbar {
  height: 70px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20;

  .tabs {
    display: flex;
    flex-direction: row;
    height: 100%;
    position: relative;

    .tab {

      width: 33.4%;
      height: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      font-size: 13px;

      & a {
        display: flex;
        flex-direction: column;
        color: var(--font-navbar);
        z-index: 2;

        & svg {
          color: var(--font-navbar)
        }
      }

      &--left {
        width: 100%;
      }

      &--right {
        width: 100%;
      }

      // QR OPENER
      &--fab {
        //200px
        width: 40%;
        height: 100%;
        background: transparent;
        border: none;
        display: flex;
      }

      span {
        font-size: 12px;
      }

      i {
        font-size: 24px;
      }

      &.is-active {
        color: #fd9d97;
      }
    }

    .fab {
      border-radius: 50%;
      background-color: #F6986C;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60px;
      height: 60px;
      font-weight: bold;
      font-size: 18px;
      color: #fff;
      position: relative;
      justify-content: center;
      transform: translate(0px, -40%);
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
  }
}

// ICONS
.icons {
  color: var(--secondary);
  font-size: 1.5em
}

// QR
.qr {
  color: white;
  font-size: 2em
}

// SCAN QR
.scanQr {
  color: white;
  width: 40px;
}