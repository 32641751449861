.barFilterContainer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  & .dates {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  & .datesInput {
    display: flex;
    align-items: center;
    border: 1px solid #999999;
    border-radius: 3px;
    padding: 10px 0 10px 13px;
    height: 39px;

    & svg {
      width: 14px;
      height: 14px;
    }

    & input[type="date"] {
      color: #000000;
      opacity: 0.4;
      border: none;
      box-sizing: border-box;
      position: relative;
      width: 100%;
      align-items: baseline;
    }

    & .needMargin {
      margin-left: 10px;
    }

    & .MuiInputBase-root {
      padding: 0;
      align-items: center;
    }

    & .MuiOutlinedInput-root {
      border: none !important
    }

    input[type="date"]::-webkit-calendar-picker-indicator {
      background: transparent;
      bottom: 0;
      color: transparent;
      cursor: pointer;
      height: auto;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: auto;
    }
  }
}

.paginationCustom {
  & .MuiToolbar-root {

    & .MuiTablePagination-displayedRows,
    .MuiBox-root {
      display: none;
    }
  }
}

.maxHeight {
  width: 100%;
  height: 40px;

  & .MuiFormControl-root {
    margin: 0
  }
}

.popUpCount {
  background: var(--orange-primary);
  border-radius: 50%;
  padding: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 600;
}

.flexColumnContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;

  & .MuiInputBase-root {
    padding: 0;
    align-items: center;
  }
}

.flexRowContainer {
  display: flex;
  align-items: center;
  gap: 20px;
}

.mTop {
  margin-top: 20px;

  & .text {
    width: fit-content;
  }
}

.resetButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: var(--orange-primary);
  color: #fff;
  border-radius: 3px;
  transition: 0.3s;
  border: 0;
  width: 39px;
  height: 39px;
  cursor: pointer;
}

.barContainer {
  height: 350px;
  width: 100%;
}

.tip {
  font-style: italic;
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 400;
  opacity: 0.2;
}

.tipDates {
  margin-top: 10px;
  padding-left: 45px;
}

.selectContainer {
  & .MuiInputBase-root {
    padding: 0
  }
}

.iconSelect {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.doughnutContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  margin-top: 25px;
}

.doughnutContainer:last-child {
  margin: auto 0;
}

.separator {
  width: 100%;
  opacity: 0.1;
  border: 1px solid #000000;
  margin: 25px 0 0 0;
}

.separator_vertical {
  opacity: 0.1;
  border: 1px solid #000000;
  height: auto;
}