.modal-container-wheel {
  position: fixed;
  display: table;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  transform: scale(0);
  z-index: 99;

  &.two {
    transform: scale(1);

    .modal-background {
      background: rgba(0, 0, 0, .0);
      animation: fadeIn .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;

      .modalQrUser {
        opacity: 0;
        animation: scaleUp .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
      }
    }

    &.out {
      animation: quickScaleDown 0s .5s linear forwards;

      .modal-background {
        animation: fadeOut .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;

        .modalQrUser {
          animation: scaleDown .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
        }
      }
    }
  }

  .modal-background {
    display: table-cell;
    background: var(--background);
    text-align: center;
    vertical-align: middle;

    .modalQrUser {
      background: var(--background);
      padding: 25px;
      display: inline-block;
      border-radius: 3px;
      font-weight: 300;
      position: relative;
      width: 60%;

      & .loyaltyGain {
        color: var(--grey);
        font-size: 12px;
        font-style: italic;
      }

      & .boldOrange {
        color: var(--orange-primary);
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    background: rgba(0, 0, 0, .0);
  }

  100% {
    background: rgba(0, 0, 0, .7);
  }
}

@keyframes fadeOut {
  0% {
    background: rgba(0, 0, 0, .7);
  }

  100% {
    background: rgba(0, 0, 0, .0);
  }
}

@keyframes scaleUp {
  0% {
    transform: scale(.8) translateY(1000px);
    opacity: 0;
  }

  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
  }
}

@keyframes scaleDown {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 1;
  }

  100% {
    transform: scale(.8) translateY(1000px);
    opacity: 0;
  }
}

@keyframes quickScaleDown {
  0% {
    transform: scale(1);
  }

  99.9% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes scaleBack {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(.85);
  }
}

@keyframes scaleForward {
  0% {
    transform: scale(.85);
  }

  100% {
    transform: scale(1);
  }
}

.userInfosModal {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 20px;
  flex-direction: column;

  & .nameUser {
    font-weight: 500;
    margin-bottom: 0 !important;
  }

  & .firstNameUser {
    margin-bottom: 0 !important;
  }

  & .reduction {
    font-style: italic;
    color: var(--orange-primary);
    font-size: 12px;
    margin-top: 5px;
    font-weight: 700;
  }

  & .reductionText {
    font-style: italic;
    color: var(--orange-primary);
    font-size: 10px;
    font-weight: 500;
  }
}

// USER IMAGE
.user_image {
  width: 100px;
  height: 100px;
  margin: 0 auto 20px auto;

  & img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

// CLOSE ICON
.closeIcon {
  font-size: 20px;
  position: absolute;
  top: 5px;
  right: 10px;
}