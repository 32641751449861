.gainContainer {
  display: flex;
  flex-direction: column;
  width: fit-content;
}

.addGainButton {
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: white;
  color: var(--orange-primary);
  font-size: 15px;
  padding: 15px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  align-self: flex-end;

  &:hover {
    background: var(--orange-primary);
    color: white;
  }

  & svg {
    margin-left: 10px;
  }
}