.overlay {
  width: 100%;
  height: 100%;
  background: var(--background);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}

.modal {
  position: fixed;
  height: 20vh;
  z-index: 20;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 85%;

  /* Center the modal */
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);

  & h2 {
    color: var(--grey);
    font-weight: 600;
    text-align: center;
    font-size: 30px;
    margin-bottom: 30px;
  }
}